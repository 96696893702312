import React, { useEffect, useState } from "react"
import { Container, Col, Row } from "react-bootstrap"
import parse from "html-react-parser"
import { graphql, Link } from "gatsby"
import { format } from "date-fns"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import Header from "../components/Header/Header"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import ImageContent from "../components/ImageContent/ImageContent"
import NegoDetails from "../components/NegoDetails/NegoDetails"
import MoreNewsDetails from "../components/MoreNewsDetails/MoreNewsDetails"
import Footer from "../components/Footer/Footer"
import clsx from "clsx"
import SEO from "../components/Seo/seo"
import { useGgfxImage } from "../components/Seo/propertyDetailsSEO"

const NewsTemplate = ({ data }) => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 250)
    })
  }, [])

  const newsDetail = data?.glstrapi.blog

  const allNews = data?.glstrapi?.blogs?.filter(
    newsValue => newsDetail?.id !== newsValue?.id
  )

  const SocialData = data.glstrapi.siteConfig

  // Date
  const postedDate = format(new Date(newsDetail?.post_on), "do MMM yyyy")
  // Date

  // Social share
  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  // const [Shareicons,setShareicons] = React.useState(false);

  // const openShareicons = () => {
  // setShareicons(true);
  // if(Shareicons === true) {
  // 	setShareicons(false);
  // }
  // }

  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  // Social share

  return (
    <div className="news-details-page">
      <SEO
        title={newsDetail?.title + " | Latest property news"}
        description={
          "Real Estate in the UAE. White & Co Real Estate. provides tips, guides, market reports, and industry news. Read our blogs to learn about the latest real estate news and to stay up to date on the Dubai property market and real estate blogs."
        }
        image={useGgfxImage(newsDetail)}
      />
      <Header layout={"news-details-template"} />

      <BreadcrumbModule newsData={newsDetail} tag="news" />

      <Container>
        <Row>
          <Col xl={8}>
            <div className="news-details-heading-wrapper">
              <h1 className="news-details-heading">{newsDetail?.title}</h1>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <i className="icon icon-calendar"></i>{" "}
                    <span>{postedDate}</span>
                  </div>
                </li>
                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <i className="icon icon-folder-open"></i> <span>Dubai</span>
                  </div>
                </li>
              </ul>
            </div>

            {newsDetail?.author && (
              <div className="news-nego-details d-xl-none">
                <div className="news-description-divider"></div>
                <div className="d-flex align-items-center">
                  <div className="news-nego-img-wrapper">
                    <img src={newsDetail?.author?.image?.url} alt={newsDetail?.author?.name} />
                  </div>
                  <div className="news-nego-name-details">
                    <div className="news-nego-name">
                      {newsDetail?.author?.name}
                    </div>
                    <div className="news-nego-desig">
                      {newsDetail?.author?.designation}
                    </div>
                  </div>
                </div>
                <div className="news-description-divider"></div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col xl={8}>
            <div
              className={clsx(`news-text-wrapper`, {
                "mt-lg-0 mt-4": newsDetail.author === null,
              })}
            >
              {newsDetail?.contents.map((content, i) => {
                return (
                  <>
                    {content.__typename ===
                      "GLSTRAPI_ComponentBlocksImageContent" && (
                      <ImageContent content={content} />
                    )}

                    {content.__typename ===
                      "GLSTRAPI_ComponentBlocksRichText" && (
                      <div className="image-text-content">
                        {parse(content.text_content)}
                      </div>
                    )}
                  </>
                )
              })}
            </div>

            <div className="news-description-divider"></div>
            <div className="news-details-share-heading">Share this post</div>
            <div className="d-flex news-details-img-social">
              <div className="news-detail-social">
                <FacebookShareButton
                  onClick={() => trackerShare("FacebookShareButton")}
                  url={shareurl}
                  className="my-share-button facebook-share"
                >
                  <i className="icon icon-fb-round"></i>
                </FacebookShareButton>
              </div>
              <div className="news-detail-social">
                <TwitterShareButton
                  onClick={() => trackerShare("TwitterShareButton")}
                  url={shareurl}
                  className="my-share-button twitter-share"
                >
                  <i className="icon icon-twitter-round"></i>
                </TwitterShareButton>
              </div>
              <div className="news-detail-social">
                <a target={"_blank"} href={SocialData.instagram_link}>
                  <i className="icon icon-insta-round"></i>
                </a>
              </div>
              <div className="news-detail-social">
                <LinkedinShareButton
                  onClick={() => trackerShare("LinkedinShareButton")}
                  url={shareurl}
                  className="my-share-button linkedin-share"
                >
                  <i className="icon icon-linkedin-round"></i>
                </LinkedinShareButton>
              </div>
            </div>
          </Col>
          <Col xl={1}></Col>
          <Col xl={3} className="d-xl-block d-none">
            <div
              className={`news-right-side-details sticky-top ${
                scroll ? "scrolled" : ""
              }`}
            >
              <NegoDetails newsData={newsDetail} />
            </div>
          </Col>
        </Row>
      </Container>

      <MoreNewsDetails newsData={allNews} />

      <Footer categoryType="category1" />
    </div>
  )
}

export default NewsTemplate

export const pageQuery = graphql`
  query GetNewsPage($articleId: ID!) {
    glstrapi {
      blogs(limit: 4) {
        id
        slug
        title
        post_on
        tile_image {
          url
        }
        ggfx_results {
          src_cftle
          transforms
        }
      }
      blog(id: $articleId, publicationState: LIVE) {
        id
        slug
        title
        publish
        post_on
        tile_image {
          url
        }
        ggfx_results {
          src_cftle
          transforms
        }
        updatedAt
        author {
          id
          email
          mobile_no
          name
          designation
          image {
            url
          }
        }
        short_description
        contents {
          ... on GLSTRAPI_ComponentBlocksRichText {
            id
            __typename
            text_content
          }
          ... on GLSTRAPI_ComponentBlocksImageContent {
            id
            __typename
            image1 {
              url
            }
            image2 {
              url
            }
          }
        }
      }
      siteConfig {
        instagram_link
      }
    }
  }
`
