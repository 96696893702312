import React from "react"
import { Link } from "gatsby"
import { People_Email } from "../../queries/common_use_query"
import "./assets/styles/_index.scss"
import NegoImg from "../../images/property-nego.png"
import { getWhatsAppURL } from "../../site/utils"
import { teamEnquiryURL } from "../../site/urls"

const NegoDetails = props => {

  const author = props?.newsData?.author

  if(!author) return null

  return (
    <div className="nego-details-wrapper">
      {author.image?.url.length > 0 && (
        <div className="nego-img-wrapper">
          <img src={author.image?.url} alt={author.name + "- White & Co Real Estate"} />
        </div>
      )}
      <div className="nego-name-details">
        <div className="nego-name">{author.name}</div>
        <div className="nego-desig">{author.designation}</div>
      </div>
      <div className="nego-btn-wrapper">
        <Link to={teamEnquiryURL} state={{team: author}} className="button button-primary">
          <i className="icon icon-nego-mail"></i> Email Agent
        </Link>
        {author.mobile_no && <a
          href={"tel:" + author.mobile_no}
          className="button button-secondary"
        >
          <i className="icon icon-nego-phone"></i>{" "}
          {author.mobile_no}
        </a>}
        {author.mobile_no && <a
          href={getWhatsAppURL(author.mobile_no)}
          target="_blank"
          className="button button-secondary mb-0"
        >
          <i className="icon icon-nego-whatsapp"></i> Click to Whatsapp
        </a>}
      </div>
    </div>
  )
}

export default NegoDetails
